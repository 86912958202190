
import Vue from 'vue';
import {
  ProposalDemographic,
  ProposalDemographics,
  ProposalGeoSelections,
  ProposalMarket,
  GeoObj,
  UnsafeAny,
} from '@/shared/types';
import { Models, Services } from '@/injectables/tokens';
import {
  CalendarServiceContract,
  ProductServiceContract,
  EnvServiceContract,
  FileServiceContract,
  GeoModelContract,
} from '@/injectables';
import OptionMap from '@/components/Proposal/Options/OptionMap.vue';
import OttAvailsCard from '@/components/Proposal/Options/OTTAvailsCard.vue';
import TargetedDemographics from '@/components/Proposal/TargetedDemographics.vue';
import { Environment } from '@/injectables/infrastructure/env/contracts';

export default Vue.extend({
  name: 'OTTMarket',

  useInjectable: [Services.Calendar, Models.NewProposal, Services.Product, Services.Env, Services.File, Models.Geo],

  inject: ['showSnackbar'],

  props: {
    forAvailsStandalone: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      default: '71',
    },
    districts: {
      type: Array,
      default: () => [],
    },
    sandbox: {
      type: Boolean,
      default: false,
    },
    availsProp: {
      type: Number,
      default: 0,
    },
    currentUser: {
      type: String,
      required: false,
    },
    ottForecasting: {
      type: Boolean,
      default: true,
    },
    dates: {
      type: Array,
      default: () => [],
    },
    product: {
      type: Object,
      required: true,
    },
  },

  components: { OptionMap, OttAvailsCard, TargetedDemographics },

  data: (): {
    mapHeight: number;
    mapWidth: number;
    demoField: string;
    demoFields: string[];
    availsLoading: boolean;
    bypassCensus: boolean;
    targetedDemographicsWidth: number;
  } => ({
    targetedDemographicsWidth: 320,
    bypassCensus: false,
    availsLoading: false,
    mapHeight: 0,
    mapWidth: 0,
    demoField: 'Demographics',
    demoFields: ['Demographics', 'Enthusiasts', 'Lifestyle', 'Occupation', 'Search History', 'Shopping'],
  }),

  watch: {
    dates(arr: string[]): void {
      if ([0, 2].includes(arr.length)) {
        this.getAvails();
      }
    },
    selectedStates(newArr, oldArr): void {
      if (this.bypassCensus || (newArr?.length === oldArr?.length && newArr.every((el, i) => el === oldArr[i]))) {
        return;
      }
      this.getCensus();
    },
    selectedCities(newArr, oldArr): void {
      if (this.bypassCensus || (newArr?.length === oldArr?.length && newArr.every((el, i) => el === oldArr[i]))) {
        return;
      }
      this.getCensus();
    },
    selectedZips(newArr, oldArr): void {
      if (this.bypassCensus || (newArr?.length === oldArr?.length && newArr.every((el, i) => el === oldArr[i]))) {
        return;
      }
      this.getCensus();
    },
    selectedDmas(newArr, oldArr): void {
      if (this.bypassCensus || (newArr?.length === oldArr?.length && newArr.every((el, i) => el === oldArr[i]))) {
        return;
      }
      this.getCensus();
    },
    selectedCounties(newArr, oldArr): void {
      if (this.bypassCensus || (newArr?.length === oldArr?.length && newArr.every((el, i) => el === oldArr[i]))) {
        return;
      }
      this.getCensus();
    },
    selectedDistricts(newArr, oldArr): void {
      if (this.bypassCensus || (newArr?.length === oldArr?.length && newArr.every((el, i) => el === oldArr[i]))) {
        return;
      }
      this.getCensus();
    },
    product(val) {
      if (val) return this.getAvails();
    },
  },

  computed: {
    avails: {
      get(): number {
        return this.availsProp;
      },
      set(avails: number): void {
        this.$emit('set-avails-prop', avails);
      },
    },
    controlBarWidth(): number {
      const minWidth = 600;
      const bufferWidth = 80;

      return this.mapWidth ? this.mapWidth - this.targetedDemographicsWidth - bufferWidth : minWidth;
    },
    availsGeoName(): string {
      const allGeoSelections = [
        ...this.selectedStatesObjArr.map(s => s?.name),
        ...this.selectedDmas.map(d => {
          return (this[Models.Geo] as GeoModelContract).cleanDmaString(d);
        }),
        ...this.selectedDistrictsObjArr?.map(d => d?.name),
        ...this.selectedCounties?.map(d => {
          const [, state] = d?.key?.split('_');
          return `${state}, ${d?.name}`;
        }),
        ...this.selectedCitiesObjArr?.map(s => s?.name),
        ...this.selectedZips?.map(s => s?.name),
      ];

      const totalElements = allGeoSelections.length;

      return `${allGeoSelections?.[0] || ''}${totalElements > 1 ? ' (+' + String(totalElements - 1) + ')' : ''}`;
    },
    market(): ProposalMarket {
      return this.$store.state.product.OTTMarket;
    },
    geoSelections(): ProposalGeoSelections {
      return this.market?.geoSelections || {};
    },
    demographics(): ProposalDemographics {
      return this.market?.demographics || {};
    },
    demographicsList(): ProposalDemographic[] {
      return this.demographics?.list || [];
    },
    targetedPopulation(): number {
      return this.demographics?.targeted || 0;
    },
    totalPopulation(): number {
      return parseInt(this.market?.demographics?.totalPopulation, 10);
    },
    censusLoading(): boolean {
      return this.$store.state['newProposal'].censusLoading;
    },
    selectedStates(): GeoObj[] {
      const { stateList = [], states = [] } = this.geoSelections;

      return [...(stateList || []), ...this.forceArray(states)];
    },
    selectedCities(): GeoObj[] {
      const { cityList = [], cities = [] } = this.geoSelections;

      return [...(cityList || []), ...this.forceArray(cities)];
    },
    selectedCounties(): GeoObj[] {
      const { countyList = [], counties = [] } = this.geoSelections;

      return [...(countyList || []), ...this.forceArray(counties)];
    },
    selectedCitiesObjArr(): GeoObj[] {
      const { cityList = [], cities = [] } = this.geoSelections;

      return [...(cityList || []), ...this.forceArray(cities)];
    },
    selectedStatesObjArr(): GeoObj[] {
      const { stateList, states = [] } = this.geoSelections;

      return [...(stateList || []), ...this.forceArray(states)];
    },
    selectedZips(): GeoObj[] {
      const { zipList, zips = [] } = this.geoSelections;

      return [...(zipList || []), ...this.forceArray(zips)];
    },
    selectedDistrictsObjArr(): GeoObj[] {
      const { districts = [] } = this.geoSelections;

      return this.forceArray(districts || []);
    },
    selectedDistricts(): string[] {
      return this.selectedDistrictsObjArr;
    },
    selectedDmas(): GeoObj[] {
      const { dmaList = [], dmas = [] } = this.geoSelections;

      return [...(dmaList || []), ...this.forceArray(dmas)];
    },
  },

  methods: {
    renderMap() {
      if (this.$refs?.ottMarketMap) this.$refs.ottMarketMap.RenderMap();
    },
    updateDates(dates) {
      this.$emit('update:dates', dates);
    },
    async onDemographicsUpdate({ demoName, listItem }): Promise<void> {
      this.$store.dispatch('newProposal/toggleMarketDemographics', { demoName, listItem, sandbox: true });
      await this.getAvails();
    },
    resetProduct(): void {
      this.bypassCensus = true;
      this.$store.dispatch('product/resetOTTMarket').then(() => {
        this.avails = 0;

        this.updateDates([]);
        if (this.$refs?.ottMarketMap) this.$refs.ottMarketMap.RenderMap();
      });
      this.bypassCensus = false;
    },
    forceArray<T>(el: T | T[]): T[] {
      return Array.isArray(el) ? el : [el];
    },
    async getAvails(): Promise<void> {
      if (
        ![
          this.selectedStates,
          this.selectedDmas,
          this.selectedDistricts,
          this.selectedCities,
          this.selectedCounties,
          this.selectedZips,
        ].some(arr => arr.length)
      ) {
        this.avails = 0;
        return;
      }

      const today = new Date();
      if (this.dates.length !== 2) {
        return this.updateDates([
          today.toISOString(),
          (this.calendarService as CalendarServiceContract).dateAfter(1, today).toISOString(),
        ]);
      }

      const segments = this.demographicsList
        .reduce((acc, curr) => {
          const currSegments = curr?.list?.map(d => {
            if (d.isSelected) {
              return d.availsSegmentId;
            }
          });

          return acc.concat(currSegments);
        }, [])
        .filter(x => x === 0 || x)
        .map(d => String(d));

      const [start, end] = this.dates;

      const startDate = new Date(new Date(start).setUTCHours(0, 0, 0, 0)).toISOString();
      const endDate = new Date(new Date(end).setUTCHours(23, 59, 0, 0)).toISOString();

      const forecastPayload = {
        startDate,
        endDate,
        product: this.product.availsProduct,
        states: this.selectedStates,
        dmas: this.selectedDmas,
        zips: this.selectedZips,
        cities: this.selectedCities,
        counties: this.selectedCounties,
        congressionalDistricts: this.selectedDistricts,
        ...(Array.isArray(segments) && segments.length ? { segments } : {}),
      };

      this.availsLoading = true;

      const { unwrap } = await (this.productService as ProductServiceContract).getOTTAvails(forecastPayload);

      const avails = unwrap();
      this.avails = Number(avails);

      this.availsLoading = false;
    },
    async getCensus(): Promise<void> {
      if (
        !this.selectedZips.length &&
        !this.selectedCities.length &&
        !this.selectedDmas.length &&
        !this.selectedStates.length &&
        !this.selectedCounties.length &&
        !this.selectedDistricts.length
      ) {
        const param = { sandbox: true };
        this.$store.dispatch('newProposal/resetOTTDemographics', param);
        return;
      }

      const selectionsForCensus = {
        ...(this.selectedStates?.length && { states: this.selectedStates }),
        ...(this.selectedDmas?.length && { dmas: this.selectedDmas }),
        ...(this.selectedZips?.length && { zips: this.selectedZips }),
        ...(this.selectedCities?.length && { cities: this.selectedCities }),
        ...(this.selectedCounties?.length && { counties: this.selectedCounties }),
        ...(this.selectedDistricts?.length && {
          congressionalDistricts: this.selectedDistricts.map(d => d.replace('99', '1')),
        }),
      };
      await this.$store.dispatch('newProposal/getCensusOTTStandAlone', selectionsForCensus);

      await this.getAvails();
    },
    setGeoSelections({ target, targetArray }: { target: string; targetArray: UnsafeAny[] }): void {
      this.$store
        .dispatch('product/setGeoSelections', {
          target,
          targetArray,
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    onWindowResize(): void {
      this.mapHeight = this.$refs?.optionMapContainer?.$el?.clientHeight || 0;
      this.mapWidth = this.$refs?.optionMapContainer?.$el?.clientWidth || 0;
      if (this.$refs?.ottMarketMap) this.$refs.ottMarketMap.adjustMap();
    },
  },

  mounted() {
    this.resetProduct();
    window.addEventListener('optimizedResize', this.onWindowResize);
    this.$nextTick(() => {
      this.mapHeight = this.$refs?.optionMapContainer?.$el?.clientHeight || 0;
      this.mapWidth = this.$refs?.optionMapContainer?.$el?.clientWidth || 0;
    });
  },

  beforeDestroy() {
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
});
