
import Vue from 'vue';
import { Services, Models } from '@/injectables/tokens';
import {
  FileServiceContract,
  ProductModelContract,
  BaseProductModelContract,
  TAvailsProduct,
  TrackerContract,
} from '@/injectables';
import { CongressionalDistrictObj } from '@/shared/types';
import DateRangePickerWithQuickPeriods from '@/components/Proposal/DatePickerWithQuickPeriods.vue';

import AvailsMap from '@/widgets/avails-calculator/avails-map.vue';
import AppBar from '@/entities/app/base-app-bar.vue';
import { EnvServiceContract, Environment } from '@/injectables/infrastructure/env/contracts';
import ProductInfo from '@/components/ProductElement/ProductInfo.vue';

import { DemographicsInput, ProductConfigCategory } from '@/app/graphql';
import { omitBy } from 'lodash';

export default Vue.extend({
  name: 'AvailsCalculator',

  useInjectable: [Services.File, Services.Env, Services.Tracker, Models.BaseProduct, Models.Router, Models.Product],

  components: { AppBar, ProductInfo, AvailsMap, DateRangePickerWithQuickPeriods },

  inject: ['showSnackbar'],

  data: (): {
    avails: number;
    dates: [string, string] | [];
    product: TAvailsProduct;
  } => ({
    avails: 0,
    dates: [],
    product: null,
  }),

  created() {
    if (this.products.length === 0) return;
    this.product = this.products[0];
  },

  mounted() {
    const { user } = this.$store.state.auth;
    const { currentAgencyInfo } = this.$store.state.agency;

    (this.tracker as TrackerContract).trackOttAvails({
      userId: user.id,
      agency: currentAgencyInfo.name,
      createdAt: new Date().toISOString(),
    });
  },

  computed: {
    canBeCleared() {
      return this.avails || Object.values(this.geoSelections || {}).some(el => el && Array.isArray(el) && el.length);
    },
    market() {
      return this.$store.state.product.OTTMarket;
    },
    geoSelections() {
      return this.market?.geoSelections || {};
    },
    demographics() {
      return this.market?.demographics || {};
    },
    products() {
      return (this[Models.Product] as ProductModelContract).availsAvailableProducts();
    },
    congressionalDistricts(): CongressionalDistrictObj[] {
      return this.agencyHasCongressionalDistrics ? this.$store.getters['product/congressionalDistricts'] : [];
    },
    agencyHasCongressionalDistrics(): boolean {
      const { agenciesWithCongressionalDistricts }: ProductModelContract = this.productEntity;

      const activeAgencyId = this.$store.state.activeAgency.PropertyId;

      const currentAgencyInfo = this.$store.state.agency.currentAgencyInfo;

      return (
        currentAgencyInfo?.congressionalDistrictAvailsEnabled ||
        agenciesWithCongressionalDistricts.includes(activeAgencyId)
      );
    },
    currentUser(): string {
      const { user } = this.$store.state.auth;
      // ensure user.name is filename safe
      return user?.email?.split('@')?.[0]?.replace(/[^\w-]+/g, '_') || 'anonymous';
    },
    small() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    async onDemographicsUpdate({ demoName, listItem }): Promise<void> {
      this.$store.dispatch('newProposal/toggleMarketDemographics', { demoName, listItem, sandbox: true });
      await this.getAvails();
    },
    resetProduct(): void {
      this.bypassCensus = true;
      this.$store.dispatch('product/resetOTTMarket').then(() => {
        this.avails = 0;

        this.dates = [];
        if (this.$refs?.standaloneOTT) this.$refs.standaloneOTT.renderMap();
      });
      this.bypassCensus = false;
    },
    async downloadAvails({ type = 'xlsx' }: { type: string }): Promise<void> {
      const prettyFileType = type === 'xlsx' ? 'Excel' : 'PDF';

      this.$store.dispatch('showSnackbar', {
        content: `Generating ${prettyFileType} file, please wait for download to start...`,
        color: 'success',
        timeout: -1,
        withLoader: true,
      });

      const trackDownloadProgress = () => {
        this.$store.dispatch('hideSnackbar');
      };

      const username = this.currentUser;
      const date = new Date().toISOString().substring(0, 10);

      const fileName = `avails calculator_${username}_${date}`;

      const { isErr, unwrapErr } = await this.downloadAvailsFile(type, fileName, trackDownloadProgress);

      if (isErr()) {
        this.$log('error', 'OTTMarket/export error', unwrapErr());

        this.showSnackbar(`Error generating ${prettyFileType} file, please try again`);
      }
    },
    downloadAvailsFile(type, name, trackDownloadProgress) {
      const geoSelections = {
        zipList: this.geoSelections?.zips?.map(z => z),
        countyList: this.geoSelections?.counties?.map(c => c),
        dmaList: this.geoSelections?.dmas?.map(d => d),
        stateList: this.geoSelections?.states?.map(s => s),
        cityList: this.geoSelections?.cities?.map(c => c),
      };
      const productName = this.product.name;
      const [startDate, endDate] = this.dates;
      const totalAvails: string = this.avails.toString();

      const isXlsx = type === 'xlsx';

      const avails = {
        startDate,
        endDate,
        geoSelections,
        totalAvails,
        productName,
        ...(isXlsx
          ? { audienceSegments: this.formatDemographics(this.demographics, true) }
          : { demographics: this.formatDemographics(this.demographics) }),
      };

      if (isXlsx) {
        return (this.fileService as FileServiceContract).downloadAvailsXlsx(
          {
            name,
            avails,
          },
          trackDownloadProgress,
        );
      }

      return (this.fileService as FileServiceContract).downloadAvailsPDF(
        {
          name,
          avails,
        },
        trackDownloadProgress,
      );
    },
    formatDemographics(demographics, listOnly): DemographicsInput {
      if (listOnly) {
        return demographics?.list
          .map(el => el.list)
          .flat()
          .filter(el => el.isSelected)
          .map(el => el.property);
      }

      const { list, ...rest } = demographics;
      const updatedList = list.map(item => {
        const { list: demoList, demo } = item;
        const updatedList = demoList.map(item =>
          omitBy(item, (value, key) => {
            return key === 'availsSegmentId';
          }),
        );
        return { demo, list: updatedList };
      });
      return {
        ...rest,
        list: updatedList,
      };
    },
    productIcon(categoryName: ProductConfigCategory): string {
      return (this[Models.BaseProduct] as BaseProductModelContract).categoryIconAndColor(categoryName).icon;
    },
  },
});
