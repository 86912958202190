
import Vue from 'vue';
import { differenceInDays } from 'date-fns';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';

export default Vue.extend({
  name: 'OTTAvailsCard',

  components: { WrapperWithTooltip },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    avails: {
      type: Number,
      default: 0,
    },
    dates: {
      type: Array,
      default: () => [],
    },
    staticDates: {
      type: Boolean,
      default: false,
    },
    availsGeoName: {
      type: String,
    },
    hideClearButton: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    expanded: boolean;
  } => ({
    expanded: true,
  }),

  methods: {
    resetProduct(): void {
      this.$emit('reset-product');
    },
  },

  computed: {
    canClearAvails(): boolean {
      return !this.hideClearButton && (this.avails || (!this.staticDates && this.dates.length));
    },
    totalDays(): number {
      if (this.dates.length === 2) {
        const [start, end] = this.dates;
        return Math.abs(differenceInDays(new Date(start), new Date(end)));
      }
      return 0;
    },
    cardWidth(): number {
      return this.expanded ? 400 : 60;
    },
    cardHeight(): number {
      return this.expanded ? 240 : 60;
    },
    dynamicFontSize() {
      const minFontSize = 12;
      const maxFontSize = 48;
      if (!this.avails || !this.$refs.textBox) return maxFontSize + 'px';
      const containerWidth = this.$refs.textBox.clientWidth;

      const textContainerClone = this.$refs.textBox.cloneNode(true);

      textContainerClone.style.width = 'fit-content';
      textContainerClone.innerText = this.avails.toLocaleString();
      textContainerClone.visibility = 'hidden';
      textContainerClone.style.fontSize = minFontSize + 'px';

      document.body.appendChild(textContainerClone);
      const textContainerWidth = textContainerClone.clientWidth;
      document.body.removeChild(textContainerClone);

      let fontSize = Math.floor((containerWidth / textContainerWidth) * minFontSize);

      fontSize = Math.max(Math.min(fontSize, maxFontSize), minFontSize);

      return fontSize + 'px';
    },
  },
});
